* {
    --main-theme-color: #2ea9f7;
    --main-theme-active-color: #fe5117;
    --main-theme-text-color: #777777;
}
.App{
    max-width: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-height: 100%;
    align-items: center;
}



@media (pointer: coarse), (hover: none) {
    [title] {
        position: relative;
        display: inline-flex;
        justify-content: center;
    }
    [title]:focus::after {
        content: attr(title);
        position: absolute;
        top: 90%;
        color: #000;
        background-color: #fff;
        border: 1px solid;
        width: fit-content;
        padding: 3px;
        font-size: 10px;
    }

}

.adminLimit {
    width: 40px;
    border-radius: 5px;
    text-align: center;
    border: #AAAAAA 1px solid;
}

.accordion .accordion-button {
    background-color: var(--main-theme-color);
    color: white;
}

.customerOrdersAccordion .accordion-button, .customerOrdersAccordion .accordion-button:after {
    background-color: var(--main-theme-color);
    color: white;
}

.adminProductModal .info {
    max-width: 650px;
}

.adminProductModal .images .item {
    max-width: 200px;
    max-height: 200px;
    object-fit: contain;
}

.adminProductModal .images>div {
    position: relative;
}

.adminProductModal .images .del_btn, .adminProductModal .images .master_btn {
    color: var(--main-theme-active-color);
    position: absolute;
    top: 8px;
    right: 8px;

}


.adminProductModal .images .master_btn {
    right: 30px;
}

.adminProductIndex {
    max-width: 30px;
    width: 30px;
    padding: 4px;
    text-align: center;
    font-size: 0.8rem;
    margin-right: 6px;
}

.adminOrders .orderItem {
    display: grid;
    grid-template-columns: 2fr 4fr 4fr 1fr 1fr 2fr 3fr;
    border-bottom: #CCC solid 1px;
    align-items: center;
}

.customerOrdersAccordion .orderItem {
    display: grid;
    grid-template-columns: 4fr 5fr 1fr 1fr 3fr;
    border-bottom: #CCC solid 1px;
}

.orderItem .link {
    color: var(--main-theme-active-color);
}

.orderModalItem, .orderModalItemsHeader{
    display: grid;
    grid-template-columns: 5fr 1fr 1fr 1fr;
    align-items: center;
    border-bottom: solid 1px #CCC;
}

.orderModalItem div:last-child, .orderModalItemsHeader div:last-child {
    text-align: right;
}


.pointer {
    cursor: pointer;
}
@media (max-width: 800px) {
    .adminOrders .orderItem{
        grid-template-columns: 1fr 1fr 1fr;
        text-align: center;
        gap: 5px;
    }


}

@media (max-width: 550px){

    .customerOrdersAccordion .orderItem {
        grid-template-columns: 10fr 14fr 3fr 3fr;
    }
    .customerOrdersAccordion .orderItem div:last-child{
        grid-column-start: 1;
        grid-column-end: 5;

    }
    .orderModalItem, .orderModalItemsHeader{
        grid-template-columns: 4fr 1fr 1fr 1fr;
    }
    
}

.customerOrdersAccordion .orderItem div:last-child{
    text-align: right;
    width: 100%;
    min-width: 100px;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

textarea{
    border-radius: 5px;
    border: 1px solid #DDD;
    outline:0px none transparent;
}

input:focus, input:active, textarea:focus, textarea:active {
    box-shadow: 0 0 3px 2px var(--main-theme-color) !important;

}

h1, h2, h3, h4, h5, h6 {
    color: var(--main-theme-text-color);
    text-align: center;

}


p, li {
    color: var(--main-theme-text-color);
}

p {
    padding: 0 10px;
}

a {
    color: var(--main-theme-active-color);
    text-decoration: none;
}

#responsive-navbar-nav.show  {
    overflow-y: scroll;

}

#responsive-navbar-nav::-webkit-scrollbar{
    display: none;
}

@media (max-width: 991px){
    .mainContainer {
        margin-top: 80px;
    }
    #responsive-navbar-nav{
        background-color: #777777;
        opacity: 0.8;
        padding: 0;
        max-width: 100vw;
        box-sizing: border-box;
        min-height: calc(100vh - 70px);
    }
    #responsive-navbar-nav .active {
        background-color: var(--main-theme-active-color);
        text-shadow: none;
    }
}

.payment_and_delivery .map iframe {
    max-height: 80vh;
}

.useBonusPoints {
    display: flex;
    justify-content: right;
    align-items: center;
}
.useBonusPoints input {
    max-width: 320px;
    font-size: 0.9em;
}

.useBonusPoints span {
    width: 80px;
    padding-left: 5px;
    font-size: 0.9em;
}

.summaryBlock .result {
    display: grid;
    grid-template-columns: 200px 200px;
    justify-content: right;
    font-size: 1.2em;
}

@media (max-width: 450px) {
    .summaryBlock .result {
        grid-template-columns: 1fr 100px;
        margin: 15px 0;
    }
}

.summaryBlock .result span:nth-child(even){
    text-align: right;
    font-weight: 600;

}

.summaryBlock .result span {
    padding: 0 5px;
}

.Filters.row{
    width: 100%;
}

#Content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    /*justify-content: space-between;*/
    gap: 4px;
    max-width: 100%;
    align-items: center;
    width: 100%;
}
#Content .ProductCard {
    height: 100%;
}

.index_slider img, .index_top_banner img {
    width: 100%;
}
.index_slider {
    margin-bottom: 15px;
}

.auth_form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px 10px;
}

.attributeFilterGroup {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    gap: 8px;
}


.userPage, .basketPage, .adminPage, .adminOrders, .adminUsersPage {
    width: 100%;
    padding: 0 10px;
}

.categoryPage .accordion {
    padding: 0 0;
}

.categoryPage .Filters {
    margin-bottom: 10px;
}


.userPageBonus {
    border-radius: 5px;
    padding: 10px 25px;
    border: solid 3px var(--main-theme-color);

}

.userPageBonus h4 {
    margin: 0;
}


.order-default, .orderOption{
    color: #777;
}
.order-canceled{
    color: darkred;
}
.order-done{
    color: forestgreen;
}

.attributeList button {
    background: white;
    border: solid 1px var(--main-theme-active-color);
    border-radius: 5px;
    padding: 0 20px;
    color: var(--main-theme-active-color);
    transition: all linear 200ms;
}

.attributeList button:hover {
    background: var(--main-theme-active-color);
    color: white;
}

@media (max-width: 900px) {
    .brandFilter button {
        font-size: 0.7em;
        padding: 4px 8px;
    }
}

.categoryPage .accordion-button {
    background-color: var(--main-theme-color);
    color: #FFFFFF;
    box-shadow: none !important;
    padding: 8px 12px;
}

.categoryPage .accordion-button.collapsed {
    background-color: rgba(141, 145, 147, 0.58);
    color: #FFFFFF;
}

.categoryPage .accordion-button.collapsed:after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23999999'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.categoryPage .accordion-button:after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFFFFF'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.attributeFilterGroup div {
    margin: 0 0;
}

.attributeFilterGroup h5 {
    margin-bottom: 0;

}



.brandFilter .active {
    background-color: var(--main-theme-active-color);
    color: #FFF;
    border-color: var(--main-theme-active-color);
}

.form-check-input.is-valid:focus, .form-check-input.is-valid:active, .was-validated .form-check-input:valid:focus, .was-validated .form-check-input:valid:active {
    box-shadow: none;
}

.form-check-input.is-valid:checked, .was-validated .form-check-input:valid:checked {
    background-color: var(--main-theme-active-color);
    border-color: var(--main-theme-active-color);
    color: var(--main-theme-text-color);
}
.form-check-input.is-valid~.form-check-label, .was-validated .form-check-input:valid~.form-check-label {
    color: var(--main-theme-text-color);
    font-weight: 600;
}
.form-check-input.is-valid {
    border-color: #AAAAAA;
}

.orderConfirmation .card {
    padding: 10px 10px;
}

.payment_delivery {
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 0;
}

.payment_delivery > div {
    width: 50%;
}
.payment_delivery .card {
    width: 100%;
    height: 100%;
    flex: 1;
}

.payment_delivery .delivery_methods label {
    font-size: 0.8em;
}

.orderConfirmation .mapModal {
    width: 100%;
    display: flex;
    justify-content: right;
    font-size: 0.8em;
    align-items: center;
}

.orderConfirmation .mapModal span:nth-child(2) {
    font-family: Lobster, SansSerif;
}

.customerBlock textarea, .customerBlock textarea:active, .customerBlock textarea:focus{
    width: 100%;
    border-radius: 5px;
    border: 1px solid #ced4da;
    padding: 6px 12px;
}

.adminUsersList .line {
    display: grid;
    grid-template-columns: 3fr 3fr 3fr 6fr;
}


.orderConfirmation .orderItemsBlock .total {
    display: grid;
    grid-template-columns: 300px;
    justify-content: right;
}

.orderConfirmation .orderItemsBlock .total > div > div {
    display: grid;
    grid-template-columns: 200px 100px;
    }

.orderConfirmation .orderItemsBlock .total {
    margin-top: 8px;
}

.orderConfirmation .orderItemsBlock .total hr {
    margin: 3px 0;
}

.orderConfirmation .orderItemsBlock .total > div > div span:nth-child(2){
    text-align: right;
    padding-right: 15px;
    font-weight: 600;
}


.orderConfirmation .orderItemsBlock .header {
    font-weight: 600;
}



.orderConfirmation .orderItemsBlock .item, .orderConfirmation .orderItemsBlock .header {
    display: grid;
    grid-template-columns: 35px auto 55px 55px 55px;
    align-items: center;
    border-bottom: solid 1px #CCCCCC;
    text-align: center;
    padding: 0 15px;
    font-size: 0.8em;
}

.orderItemImg {
    padding: 4px 0px;
    margin-left: auto;
    margin-right: auto;
}

.orderConfirmation .orderItemsBlock .item span {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.orderConfirmation .orderItemsBlock .item span:nth-child(5), .orderConfirmation .orderItemsBlock .header span:nth-child(5) {
    justify-content: right;

}

.orderConfirmation .orderItemsBlock .item span:nth-child(3), .orderConfirmation .orderItemsBlock .header span:nth-child(3) {
    border-left: solid 1px #CCCCCC;
    border-right: solid 1px #CCCCCC;
}
.orderConfirmation .orderItemsBlock .item span:nth-child(4), .orderConfirmation .orderItemsBlock .header span:nth-child(4) {
    border-right: solid 1px #CCCCCC;

}

.orderConfirmation .orderItemsBlock .item span:first-child{
    text-align: left;
    padding-right: 8px;
}

.orderConfirmation .orderItemsBlock .item span:last-child{
    font-weight: 600;
}

.adminBrandList {
    display: grid;
    grid-template-columns: 3fr 1fr 1fr;
}

.userBonusAccordion .accordion-button,
.userRoleAccordion .accordion-button {
    padding: 10px 20px;
}

.addAttributeCategoryAccordion .accordion-button,
.productAdminAttributesAccordion .accordion-button,
.addPromotionAccordion .accordion-button,
.productAdminMetaAccordion .accordion-button{
    padding-top: 4px;
    padding-bottom: 4px;
}

.addAttributeCategoryAccordion .accordion-button.collapsed,
.addPromotionAccordion .accordion-button.collapsed,
.productAdminMetaAccordion .accordion-button.collapsed,
.addCategoryAccordion .accordion-button.collapsed,
.addBrandAccordion .accordion-button.collapsed,
.userBonusAccordion .accordion-button.collapsed,
.userRoleAccordion .accordion-button.collapsed,
.productAdminAttributesAccordion .accordion-button.collapsed
{
    background-color: #DDD;
}


.attributeItem:hover {
    font-weight: 800;
}

.customerBlock input {
    margin-bottom: 5px;
}

.adminCategoryImg {
    max-width: 200px;
    border-radius: 5px;
}


.paginationDelimiter a{
    border-top: none;
    border-right: none;
    border-bottom: none;
    cursor: default;
}
.paginationDelimiter:hover, .paginationDelimiter a:hover {
    background-color: transparent !important;
    color: var(--main-theme-text-color) !important;
}

@media (max-width: 960px) {
    .orderConfirmation {
        width: 100%;
        padding: 6px 6px;
    }
    .payment_delivery{
        flex-direction: column;
    }
    .payment_delivery > div {
        width: 100%;
    }
}


.mobileCatalogue .accordion-button, .mobileCatalogue .mobileMenuSubcategory {
    background-color: #777777 !important;
    border-radius: 0 !important;
    padding-left: 25px;
    box-shadow: none !important;

}

.mobileCatalogue .accordion-button:after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFFFFF'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.mobileCatalogue .accordion-button a, .mobileCatalogue .mobileMenuSubcategory {
    color: #FFFFFF;
}

.mobileCatalogue .mobileMenuSubcategory {
    padding-left: 50px;
}

.mobileCatalogue  .accordion-item{
    border: none !important;
}

.mobileCatalogue .accordion-collapse{
    background-color: #777777 !important;
}

.mobileCatalogue .mobileMenuSubcategories .active .mobileMenuSubcategory  {
    background-color: var(--main-theme-active-color) !important;
}

.mobileCatalogue {
    display: none;
}

.delivery_zones {
    color: var(--main-theme-active-color);
}

@media (max-width: 991px){
    .mobileCatalogue {
        display: block;
    }
}

.promotion_img {
    height: 300px;
    max-height: 300px;
    object-fit: contain;
}


.sideBar .container, .sideBar .container .sideMenuSubcategory {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4px 16px;

}
.sideBar {
    display: flex;
    align-items: flex-start;
    padding-top: 0;
}

.sideBar .container {
    padding-top: 0;
}

.sideBar .accordion {
    width: 100%;
}

.sideBar .accordion-item {
    border-radius: 0;
}

.sideBar .sideMenuSubcategories .accordion-collapse {
    border-bottom: solid 1px #BBB;
    border-radius: 0;
}

.sideBar .accordion-button:focus,
.sideBar .accordion-button:active{
    box-shadow: none;
}

.sideBar .accordion-button, .sideBar .category {
    background-color: var(--main-theme-color);
    color: #FFF;
}

.sideBar .accordion-button a {
    opacity: 1;
    color: #FFF;
    font-family: Days;
    font-weight: 200;
}

.accordion-button::after ,.sideBar .accordion-button::after {
    background-image: none !important;
    content: "⮝";
    color: #FFF;
    font-family: Days;
    rotate: 180deg;
}

.mainContainer .accordion-button.collapsed::after, .sideBar .accordion-button.collapsed::after {
    background-image: none;
    content: "⮝";
    color: #FFF;
    font-family: Days;
    rotate: 180deg;
}

.accordion-button:active, .accordion-button:focus {
    box-shadow: none;
}

.sideMenuSubcategories .sideMenuSubcategory  {
    font-family: Exo2;
}

.sideMenuSubcategories > a > div {
    transition: all 200ms ease-in-out;
    color: var(--main-theme-text-color);
    background-color: #FFFFFF;
}

.sideMenuSubcategories .active div {
    font-weight: 800;
    color: #fff;
    background-color: var(--main-theme-active-color);
}

.sideMenuSubcategories > a > div:hover {
    background: var(--main-theme-color);
    color: #FFF;
}

.sideBar a {
    text-decoration: none;
}

.CategoryCard {
    padding: 10px 0px;
    height: 100%;
}
.CategoryCard .container {
    height: 100%;
}

.CategoryCard .container .content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
}

.CategoryCard .categoryImg {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.CategoryCard .categoryImg img {
    width: 200px;
    height: 140px;
    min-width: 200px;
    object-fit: cover;
    border-radius: 5px;
}

.CategoryCard .container a {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
}

.CategoryCard .container a div {
    font-family: Days;
    color: #777;
}

.CategoryCard .container a div:hover {
    color: var(--main-theme-active-color);
}

.clearFilter {
    width: 100%;
    text-decoration: none;
    text-align: right;
    display: block;
    color: var(--main-theme-active-color);
}

.clearFilter:hover {
    color: var(--main-theme-active-color);
}

.ProductCard{
    margin-bottom: 4px;
}

@media (max-width: 1050px){
    .ProductCard{
        margin-bottom: 0;
    }
}
#adminProductModalForm .ProductCard {
    max-width: 300px;
}

.ProductCard img {
    height: 200px;
    max-width: 200px;
    object-fit: scale-down;
    margin-top: 10px;
}

.ProductCard a {
    text-decoration: none;
}

.ProductCard, .CategoryCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
    min-width: 220px;
}

.ProductCard .desc {
    display: flex;
    flex-direction: column;
    align-items: center;
}


.ProductCard .desc h4 {
    text-transform: uppercase;
}

.ProductCard .price {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
}

.ProductCard .oldPrice {
    text-decoration: line-through;
    font-size: 0.7em;
    position: absolute;
    left: 45px;

}
.ProductPage .oldPrice {
    text-decoration: line-through;
    font-size: 0.7em;
}

.ProductPage .price {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.ProductPage .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.ProductPage .product {
    max-height: 400px;
}



.ProductPage .qtyBar input {
    border: solid #777777 1px;
    width: 50px;
    text-align: center;
    border-left: none;
    border-right: none;
    height: 100%;
    box-shadow: none !important;
    border-radius: 0;
}

.ProductPage .qtyBar {
    margin: 12px 0;
    display: flex;
}






.successImg, .emptyBasket{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    width: 100%;
    max-width: 100%;
}

.successImg div, .emptyBasket div {
    max-width: 100%;
    padding: 0 15px;
}

.successImg img, .emptyBasket img{
    max-width: 100%;
    object-fit: cover;
    width: 100%;

}


.successImg div button, .emptyBasket div button{
    margin-top: 15px;
    width: 100%;
}

.successImg img, .emptyBasket img {
    border-radius: 5px;
    margin-top: 10px;
    max-width: 100vw;
    box-shadow: 2px 2px 5px 3px rgba(0, 0, 0, 0.2);

}

@media (max-width: 992px){
    .successImg, emptyBasket {
        min-width: 100vw;
    }
    .successImg div, .emptyBasket div {
        padding: 0 0;
    }
}


@media (max-width: 500px){
    h1 {
        font-size: 1.5rem;
    }
}


.searchBar {
    width: 270px;
    display: flex;
    flex-direction: column;
    margin-left: 5px;
}


.searchBar input {
    padding: 5px 5px;
    width: 220px;
    height: 100%;
    border:  1px solid #DDD;
    box-shadow: none;
    outline: none;
    border-radius: 5px 0 0 5px;
}
@media (min-width: 1240px){

    .searchBar input {
        width: 230px;
    }
}

.searchBar .search_btn {
    width: 35px;
    padding: 0px 0px;
    background-color: white;
    border: 1px solid #DDD;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.searchBar .search_btn span {
    font-size: 1.2em;
    color: #777777;
}

.searchBarList {
    transform: translateX(-11px);
    position: absolute;
    top: 46px;
    display: flex;
    flex-direction: column;
    background-color: white;
    width: 255px;
    border-radius: 5px;
    box-shadow: -1px -1px 3px 3px rgba(0,0,0,0.1);
}

.searchBarItem {
    padding: 8px 5px;
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 5fr;
    border-top: 1px #CCC solid;
    line-height: 1rem;
}

.searchBarItem:last-child{
    border-bottom: 1px #CCC solid;
}

.searchBarItem .img {
    display:flex;
    justify-content: center;
    height: 100%;
    align-items: center;
}

.searchBarItem .img img {

    aspect-ratio: 1;
    object-fit: contain;
    width: 25px;
    transition: 0.14s all linear;
}

.searchBarItem .img img:hover {
    scale: 7;
    border-radius: 2px;
    box-shadow: 1px 1px 1px 1px rgba(0,0,0,0.2);
    z-index: 99999;
}
@media (min-width: 992px) and (max-width: 1235px){
    .searchBar {
        margin-left: 15px;
    }
    .mainContainer {
        padding-left: 7px;
    }

}
@media (max-width: 992px){
    .searchBarList{
        transform: translateX(0);
    }
    .searchBar {
        width: 280px;
        margin: 0 0;
        padding-left: 15px;
    }
    .searchBar input {
        width: 200px;
        font-size: 0.9rem;
        margin-left: 10px;
    }
    .searchBar .search_btn {
        width: 30px;
    }

    .mainContainer > .container{
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 6px;
    }
    #Content {
        gap: 6px;

    }
}

@media (max-width: 500px){
    .searchBar {
        width: 170px;
        margin: 0 0;
        padding-left: 15px;
    }
    .searchBar input {
        width: 115px;
        font-size: 0.7rem;
        margin-left: 10px;
    }
    .searchBar .search_btn {
        width: 25px;
    }
}

.searchLink {
    color: var(--main-theme-text-color);
}

.mainContainer {
    display: grid;
    grid-template-columns: 23% 77%;
    max-width: 1200px;
    flex-grow: 1;
    gap: 20px;

}

.mainContainer > .container {
    padding: 0 0px 0 0px;
}


@media (max-width: 1240px){
    .mainContainer > .container{
        padding: 0 35px 0 0;
    }
}


@media (min-width: 768px) and (max-width: 992px){
    .mainContainer > .container{
        width: 100%;
        max-width: 100vw;
        padding: 0 25px 0 10px;
    }
}

@media (max-width: 768px){
    .mainContainer > .container{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 0;
        width: 100%;
        max-width: 100%;
        padding: 0 0px 0 0px;
        box-sizing: border-box;
    }
    #Content {
        gap: 6px;
        max-width: 100%;
        padding: 0 6px;
    }


}


.pagination {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;

}

.pagination .page-link {
    border-radius: 0 !important;
    color: var(--main-theme-text-color);
}

.pagination .page-link:hover {
    color: #FFFFFF;
    background-color: var(--main-theme-color);
}

.pagination .active .page-link{
    background-color: var(--main-theme-active-color);
    border-color: var(--main-theme-active-color);
    color: #FFFFFF;
}



.ProductCard .newPrice, .ProductPage .newPrice {
    color: var(--main-theme-active-color);
}

.ProductCard .children, .ProductPage .children {
    display: flex;
    gap: 3px;
    flex-wrap:wrap;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

.ProductCard .bonusPoints {
    display: flex;
    align-items: center;
    gap: 2px;
    position: absolute;
    top: 15px;
    left: 5px;
    justify-content: left;
    width: 100%;
    height: 0;
}

.bonusPoints span {
    font-size: 1.2em;
}

.ProductCard .children button, .ProductPage .children button {
    border: var(--main-theme-color) solid 1px;
    color: var(--main-theme-color);
    padding: 5px 5px;
}

.ProductCard .children button.active, .ProductCard .children button:hover,
.ProductPage .children button.active, .ProductPage .children button:hover{
    background: var(--main-theme-color);
    color: #FFF;
}

.ProductCard .AddToCartButton, .ProductPage .AddToCartButton {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    transition: all 300ms ease-in-out;
    font-family: Days;
    font-weight: 400;
}

.ProductCard .AddToCartButton svg, .ProductPage .AddToCartButton svg{
    fill: var(--main-theme-active-color);
    transition: all 200ms linear;
}

.ProductCard .AddToCartButton:hover svg, .ProductPage .AddToCartButton:hover svg{
    fill: #FFF;
}



.ProductCard>div{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

.ProductCard>div>div {
    display: flex;
    flex-direction: column;
    align-items: center;
}
@media (min-width: 990px) {
    .ProductCard .AddToCartButton:hover, .ProductPage .AddToCartButton:hover {
        scale: 1.25;
    }
}

.ProductPage .bonusPoints {
    display: flex;
    align-items: center;
    gap: 2px;
    width: 100%;
    margin-top: -5px;
    justify-content: center;
}

.ProductPage {
    display: grid;
    grid-template-areas:
    "a b"
    "c c";
    width: 100%;
}


@media (max-width: 850px){
    .adminUsersList .line {
        grid-template-columns: 1fr 1fr 1fr;
    }
    .adminUsersList .line div:nth-child(4){
        grid-column-start: 1;
        grid-column-end: 4;
    }

    .ProductPage {
        display: grid;
        grid-template-areas:
    "a a"
    "b b"
    "c c";
    }
    .ProductPage > div {
        width: 96vw;
    }
}
@media (max-width: 650px){
    .adminUsersList .line {
        grid-template-columns: 1fr 1fr;
    }
    .adminUsersList .line div:nth-child(3), .adminUsersList .line div:nth-child(4){
        grid-column-start: 1;
        grid-column-end: 3;
    }
}

.ProductPage .CarouselContainer {
    grid-area: a;
}

.ProductPage .description {
    align-self: start;
    grid-area: c;
}

.ProductPage .product {
    width: 475px;
    grid-area: b;
}

.ProductPage .price {
    font-size: 2.5rem;
}

.CarouselContainer {
    height: 400px;
    width: 400px;
}

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%232ea9f7' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%232ea9f7' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
}

.ProductPage .noImage {
    margin: 100px 100px;
}

.ProductPage .description {
    width: 100%;
}

.loginPage input {
    margin-bottom: 15px;
}

.loginPage .switch {
    margin-top: 15px;
    text-align: right;
}

.ProductCard .stars img, .product .content .stars img{
    width: 100px;
    height: 25px;
    margin-top: 0;
}
.ProductCard .stars {
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
    flex-wrap: nowrap;
    height: 35px;
    width: 100%;
    position: absolute;
}


.product .content .stars {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    height: 35px;
    width: 100%;
}


.ProductCard .stars p, .product .content .stars p {
    margin-bottom: 0;
    font-size: 1.2em;
}

.loginPage .card form {padding: 10px 10px;}



.basketItem, .BasketContainer {
    display: grid;
    grid-template-columns: 40px 12fr 3fr 3fr 3fr 10px;
    align-items: center;
    color: var(--main-theme-text-color);
    gap: 5px;
}

.navigationBasketCounter {
    position: absolute;
    left:15px;
    top: 15px;
    border-radius: 4px;
    background-color: var(--main-theme-active-color);
    width: 15px;
    height: 15px;
    font-size: 0.7rem;
    text-align: center;
    vertical-align: center;
    font-family: Exo2;
}

.BasketContainer {
    font-weight: 800;
}

.basketItem .basketItemImg img, .orderItemImg img{
    width: 30px;
    transition: all 200ms;
    z-index: 99;
}

.basketItem .basketItemImg:hover {
    z-index: 98;
}

.basketItem .basketItemImg img:hover{
    scale: 5;
    background-color: #FFFFFF;
}

@media (max-width: 450px){
    .basketItem .basketItemLink {
        font-size: 0.7rem;
    }
}

@media (max-width: 800px){
    .basketItem .basketItemImg img:hover {
        margin-left: 50px;
        position: absolute;
    }
}

.basketItem div, .BasketContainer div {
    justify-self: center;
    padding: 10px 0;
}
.basketItem div:first-child, .BasketContainer div:first-child{
    justify-self: start;
}

.basketItem div:nth-child(5){
    font-weight: 600;
}

.basketItem .qtyBar {
    display: flex;
    line-height: 20px;
    height: 60px;
}

.basketItem .oldPrice {
    text-decoration: line-through;
    font-size: 0.7em;
    width: 100%;
    text-align: center;
    padding: 0 0;
}

.basketItem .newPrice {
    color: var(--main-theme-active-color);
    padding: 0 0;
}

.basketItem .DelItemBtn {
    background-color: transparent;
    border: none;

}
.basketItem .DelItemBtn span {
    font-size: 20px;
    padding-top: 6px;
    color: var(--main-theme-text-color);
}

.basketItem .DelItemBtn span:hover {
    color: var(--main-theme-active-color);
}

.basketItemLink {
    position: relative;
}
.basketItem .notInStock {
   font-size: 1.2rem;
}

.basketItem .basketItemLink a{
    color: var(--main-theme-text-color);
    text-transform: uppercase;
    text-decoration-line: none;
    font-size: 0.9em;
}

.basketItem .basketItemLink a:hover{
    color: var(--main-theme-active-color);
}

.basketItem .qtyBar input {
    border-left: none;
    border-right: none;
    border-top: solid 1px var(--main-theme-text-color);
    border-bottom: solid 1px var(--main-theme-text-color);
    border-right: 0;
}

.basketItem .qtyBar .QtyBtn, .ProductPage .qtyBar .QtyBtn {
    background-color: #FFFFFF;
    padding: 8px 8px;
    border: solid 1px var(--main-theme-text-color);
}

.basketItem .qtyBar .QtyBtn:hover, .ProductPage .qtyBar .QtyBtn:hover {
    background-color: var(--main-theme-active-color);
    color: #FFFFFF;
}

.basketItem .qtyBar .IncrBtn, .ProductPage .qtyBar .IncrBtn {
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
}

.basketItem .qtyBar .DcrBtn, .ProductPage .qtyBar .DcrBtn {
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
}

.basketItem input {
    width: 30px;
    text-align: center;
}

.BasketTotal h3, .BasketTotal h4 {
    text-align: right;
}

.BasketTotal {
    display: flex;
    flex-direction: column;
    justify-content: right;
}

.BasketTotal hr {
    z-index: 5;
}

.ClearBasket {
    font-size: 0.7em;
}
.basketPage + .basketBlock, .orderConfirmation + .basketBlock {
    display: none;
}
.basketBlock {
    position: fixed;
    bottom: 70px;
    right: 40px;
    width: 85px;
    height: 85px;
    border-radius: 50%;
    border: solid 5px var(--main-theme-active-color);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--main-theme-active-color);
    opacity: 0.5;
    animation: ease-in-out blink infinite 3s;
}

.basketBlock:hover {
    opacity: 1;
    background-color: var(--main-theme-active-color);
    border-color: var(--main-theme-active-color);
}

.shoppingCart {
    display: flex;
    align-items: center;
    color: #FFFFFF;
    width: 100%;
    height: 100%;
    justify-content: center;
    flex-direction: column;
    border-radius: 50%;
    border: 3px solid #FFFFFF;

}
.shoppingCart span {
    padding-top: 5px;
    font-size: 1.7rem;
}

.shoppingCart span svg {
    fill: #FFF;
    margin-top: -8px;
}

.shoppingCart .basketItems {
    font-size: 1.1rem;
}

.basketBlock>a{
    text-decoration: none;
    width: 100%;
    height: 100%;
}

.shoppingCart div:nth-child(2) span {
    font-family: Lobster;
    font-size: 0.9em;
}

.shoppingCart span:nth-child(2) {
    font-weight: 800;
    margin-top: 8px;
    color: #FFFFFF;
    font-family: Lobster;
}

.shoppingCart span:first-child {
    align-self: center;
    color: #FFFFFF;
}

.stockListItem {
    display: grid;
    grid-template-columns: 1fr 4fr 1fr 1fr;
    border-bottom: #777777 solid 1px;
    align-items: center;
    font-size: 0.9rem;
    gap: 5px;
}



@keyframes blink {
    50% {
        text-shadow: 2px 2px 5px #e7ecff;
        opacity: 0.7;
    }
}


@keyframes fadeIn {
    0% {
        height: 0;
    }
    100% {
        heigth: 100%;
    }
}


@media (max-width: 600px) {
    .BasketContainer, .basketItem {
        font-size: 0.7em;

    }

    .basketItem div, .BasketContainer div:nth-child(2) {
        justify-self: start;

    }
}

@media (min-width: 1200px) {
    .mainContainer {width: 1200px;}
}

@media (max-width: 991px) {
    .sideBar {
        display: none;

    }
    .mainContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100vw;
        gap: 0;
        max-width: 100vw;
    }


    .CarouselContainer {width: 100%;}

    .CarouselContainer .carousel-item {

    }

    .ProductPage .product {
        width: 100%;
    }
}



@media (min-width: 991px) and (max-width: 1200px) {

    .sideMenuSubcategory {
        font-size: 0.85em;
    }

    .mainContainer {
        width: 100%;
    }
}
@media (max-width: 780px) {
    #Content {
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width: 530px) {
    #Content {
        grid-template-columns: 1fr;
    }
}

@media (min-width: 1050px) and (max-width: 1160px) {

    .Filters.row{
        width: 100%;
    }

}

@media (max-width: 500px) {
    .ProductCard, .CategoryCard {
        width: 100%;
    }
}



/*VK ID*/
.VkIdWebSdk__button_reset {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;
    color: inherit;
    font: inherit;
    line-height: normal;
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    -webkit-appearance: none;
}

.VkIdWebSdk__button {
    background: var(--main-theme-color);
    cursor: pointer;
    transition: all .1s ease-out;
}

.VkIdWebSdk__button:hover{
    opacity: 0.8;
}

.VkIdWebSdk__button:active {
    opacity: .7;
    transform: scale(.97);
}

.VkIdWebSdk__button {
    border-radius: 8px;
    width: 100%;
    min-height: 44px;
}

.VkIdWebSdk__button_container {
    display: flex;
    align-items: center;
    padding: 8px 10px;
}

.VkIdWebSdk__button_icon + .VkIdWebSdk__button_text {
    margin-left: -28px;
}

.VkIdWebSdk__button_text {
    display: flex;
    font-family: -apple-system, system-ui, "Helvetica Neue", Roboto, sans-serif;
    flex: 1;
    justify-content: center;
    color: #ffffff;
}

#BasketBlock.shake {
    animation: shakeCart .5s ease-in-out forwards;
}

@keyframes shakeCart {
    25% {
        transform:translateX(6px)
    }
    50% {
        transform:translateX(-4px);
    }
    75% {
        transform:translateX(2px);
    }
    100% {
        transform:translateX(0);
    }
}


/* 404 page */




.container_404{
    height: 100%;
    width: 100%;
    margin: 0px;
    overflow: hidden;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
}



.h2_404{

    font-size: 32px;
    text-transform: uppercase;
    text-align: center;
    width: 100%;
    display: block;
    color: #ff005d;
    font-weight: 300;
    font-family: Days;
    animation: fadeInText 500ms ease-in 0.1s forwards, flicker4 5s linear 7.5s infinite, hueRotate 6s ease-in-out 3s infinite;

}

.sign_404 {
    font-family: Exo2;
    font-size: 8rem;
    color: #ff005d;
}
#id_404_1{
    animation: flicker1 5s linear 7.5s infinite, hueRotate 6s ease-in-out 3s infinite;
}
#id_404_2{
    animation: flicker2 5s linear 7.5s infinite, hueRotate 6s ease-in-out 3s infinite;
}
#id_404_3{
    animation: flicker3 5s linear 7.5s infinite, hueRotate 6s ease-in-out 3s infinite;
}



@keyframes flicker1{
    0%  {color: #ff005d; text-shadow: #ff005d;}
    1%  {color: transparent; text-shadow: transparent;}
    3%  {color: transparent; text-shadow: transparent;}
    4%  {color: #ff005d; text-shadow: #ff005d;}
    6%  {color: #ff005d; text-shadow: #ff005d;}
    7%  {color: transparent; text-shadow: transparent;}
    13% {color: transparent; text-shadow: transparent;}
    14% {color: #ff005d; text-shadow: #ff005d;}
    100%{color: #ff005d; text-shadow: #ff005d;}
}

@keyframes flicker2{
    0%  {color: #ff005d; text-shadow: #ff005d;}
    50% {color: #ff005d; text-shadow: #ff005d;}
    51% {color: transparent; text-shadow: transparent;}
    61% {color: transparent; text-shadow: transparent;}
    62% {color: #ff005d; text-shadow: #ff005d;}
    100%{color: #ff005d; text-shadow: #ff005d;}
}

@keyframes flicker3{
    0%  {color: #ff005d; text-shadow: #ff005d;}
    1%  {color: transparent; text-shadow: transparent;}
    10% {color: transparent; text-shadow: transparent;}
    11% {color: #ff005d; text-shadow: #ff005d;}
    40% {color: #ff005d; text-shadow: #ff005d;}
    41% {color: transparent; text-shadow: transparent;}
    45% {color: transparent; text-shadow: transparent;}
    46% {color: #ff005d; text-shadow: #ff005d;}
    100%{color: #ff005d; text-shadow: #ff005d;}
}

@keyframes flicker4{
    0%  {color: #ff005d;text-shadow:0px 0px 4px #ff005d;}
    30% {color: #ff005d;text-shadow:0px 0px 4px #ff005d;}
    31% {color: #ffffff;text-shadow:0px 0px 4px #ffffff;}
    32% {color: #ff005d;text-shadow:0px 0px 4px #ff005d;}
    36% {color: #ff005d;text-shadow:0px 0px 4px #ff005d;}
    37% {color: #ffffff;text-shadow:0px 0px 4px #ffffff;}
    41% {color: #ffffff;text-shadow:0px 0px 4px #ffffff;}
    42% {color: #ff005d;text-shadow:0px 0px 4px #ff005d;}
    85% {color: #ffffff;text-shadow:0px 0px 4px #ffffff;}
    86% {color: #ffffff;text-shadow:0px 0px 4px #ffffff;}
    95% {color: #ffffff;text-shadow:0px 0px 4px #ffffff;}
    96% {color: #ff005d;text-shadow:0px 0px 4px #ff005d;}
    100%{color: #ff005d;text-shadow:0px 0px 4px #ff005d;}
}

@keyframes fadeInText{
    1%  {color: #ffffff;text-shadow:0px 0px 4px #ffffff;}
    70% {color: #ff005d;text-shadow:0px 0px 14px #ff005d;}
    100%{color: #ff005d;text-shadow:0px 0px 4px #ff005d;}
}

@keyframes hueRotate{
    0%  {
        filter: hue-rotate(0deg);
    }
    50%  {
        filter: hue-rotate(-120deg);
    }
    100%  {
        filter: hue-rotate(0deg);
    }
}



